.qr_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  position: sticky;
  position: -webkit-sticky;
  height:fit-content;
  top:159px;
  align-items: center;
  margin-bottom: 32px;
}

.qr_parent_box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 23px;
  gap: 10px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.qr_sub_box {
  background: #ffffff;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 16px;
}
.qr_img{
  position: relative;
  width: 300px;
  height: 300px;
}
.export_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 382px;
  height: 40px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.export_title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(18, 18, 18, 0.87);
}

.export_select_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 16px;
  width: 302px;
  height: 40px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.export_select_container:focus-within{
  border-color: #5b67eb;
  background-color: rgba(0,0,0,0.04);
  box-shadow: 0 0 0 2px rgba(91, 103,235,0.5);
  transition: 0.5s;
}
.export_icon {
  margin-left: 8.33%;
  margin-top: 8.33%;
  margin-bottom: 8.33%;
  color: rgba(18, 18, 18, 0.6);
}

.export_option_selector{
  padding: 0px;
  gap: 10px;
  width: 85%;
  height: 30px;
  cursor: pointer;
  border: 0;
  margin-right: 3%;
  background: transparent;
  outline: none;
  flex: none;
  order: 1;
  flex-grow: 1;
}
.export_option_selector:focus ~.export_icon{
  filter: invert(42%) sepia(40%) saturate(5637%) hue-rotate(222deg) brightness(96%) contrast(91%);
}

.export_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 382px;
  cursor: pointer;
  height: 40px;
  background: #5B67EB;
  border-radius: 8px;
  border:0;
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #FAFAFA;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.export_btn:hover {
  background-color: #4652ce;
  box-shadow: 0 0 0 2px rgba(91, 103, 235, 0.5);
}
canvas{
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
@media screen and (max-width:430px){
  .qr_container {
    padding: 0px;
    margin: auto;
    margin-bottom: 32px;
  }
}
