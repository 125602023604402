*::before,
*::after {
    box-sizing: border-box;
}

.box {
  display: flex;
  width: fit-content;
  margin: auto;
  gap: 54px;
}

@media screen and (max-width:768px){
  .box {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    margin: auto;
    gap:0
  }
}