body {
  margin: 0;
  font-family: "SF Pro text";
  position: relative;
  background-color: #FAFAFA;
}

@font-face {
  font-family:"SF Pro text" ;
  src: local("SF Pro Text"),url("./assets/fonts/SF_Pro_Text/SF-Pro-Text-Regular.otf")
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
