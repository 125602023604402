/* .container {
  border:1px solid red;
} */
.border{
  width:400px;
  margin: 0;
}

.option_drop_container{
  font-family: "SF Pro Text";
  font-style: normal;
  width: 300px;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  cursor: pointer;
  color: rgba(18, 18, 18, 0.6);
  margin-top: 8px;
}
.option_drop_container img {
  left: 0.16px;
  right: 0.16px;
  top: 5.33px;
  bottom: 5.33px;
}
.size_title{
  margin-top: 32px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(18, 18, 18, 0.87);
}
.size_sub_title {
  height: 16px;
  margin-top: -8px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: rgba(18, 18, 18, 0.6);
}
.size_container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 400px;
}
.size_width ,.size_height{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  width: 196px;
  height: 40px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.size_width:focus-within,.size_height:focus-within{
  border-color: #5b67eb;
  background-color: rgba(0,0,0,0.04);
  box-shadow: 0 0 0 2px rgba(91, 103,235,0.5);
  transition: 0.5s;
}
.width__icon ,.height__icon,.margin__icon{
  color: rgba(18, 18, 18, 0.6);
  width: 16px;
  height: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
}
.width_input ,.height_input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  border: 0;
  padding-left: 4px;
  outline:none;
  height: 30px;
  width: 132px;
  background: transparent;
  flex: none;
  order: 1;
  flex-grow: 1;
}
.margin_box {
  box-sizing: border-box;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  width: 400px;
  height: 40px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.margin_box:focus-within{
  border-color: #5b67eb;
  background-color: rgba(0,0,0,0.04);
  box-shadow: 0 0 0 2px rgba(91, 103,235,0.5);
  transition: 0.5s;
}
.margin_input {
  background: transparent;
  display: flex;
  flex-direction: row;
  padding-left: 4px;
  align-items: flex-start;
  gap: 10px;
  outline: none;
  border: 0;
  width: 336px;
  height: 30px;
  flex: none;
  order: 1;
  flex-grow: 1;
}
.width_input:focus~.width__icon,.height_input:focus~.height__icon,.margin_input:focus~.margin__icon{
  filter: invert(42%) sepia(40%) saturate(5637%) hue-rotate(222deg) brightness(96%) contrast(91%);
}
.dot_select_container,.sqaure_select_container,.corner_select_container {
  margin: 16px 0px 0px 0px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px 12px 16px;
  gap: 16px;
  width: 400px;
  height: 40px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.dot_select_container:focus-within,.sqaure_select_container:focus-within,.corner_select_container:focus-within{
  border-color: #5b67eb;
  box-shadow: 0 0 0 2px rgba(91, 103,235,0.5);
  background-color: rgba(0,0,0,0.04);
  transition: 0.5s;
}
.dot_select_container select ,.sqaure_select_container select,.corner_select_container select{
  cursor: pointer;
  outline: none;
  width: 400px;
  height: 37px;
  border: 0;
  color:#848381;
  background-color:transparent;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.color_picker_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 400px;
  height: 40px;
}
.color_picker_box p {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(18, 18, 18, 0.87);
}
.color_picker_box input {
  margin-right: 8px;
  cursor: pointer;
}
.emptydiv{
  height:50vh;
}
@media screen and (max-width:768px){
  .emptydiv{
    height:5vh;
  }
}