.navbar_container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #00000008;
}

.logo__img {
  width: 48px;
  height: 32px;
  margin: 16px 0px 16px 40px;
}

.title__container {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #000000;
}

.right_btn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 241px;
  height: 40px;
  margin-right: 40px;
  top: 16px;
  
}
.button_external {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  border: 0;
  cursor: pointer;
  width: 126px;
  height: 40px;
  color: white;
  background: #0fbdb8;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_external:hover {
  background: #0ea9a5;
  border-radius: 8px;
  box-shadow: 0 0 0 2px rgba(16, 207, 201, 0.1);;
}

.next_button_external {
  color: white;
  background: #5b67eb;
}

.next_button_external:hover {
  background-color: #4652ce;
  box-shadow: 0 0 0 2px rgba(91, 103, 235, 0.5);
}
@media screen and (max-width: 450px) {
  .webbtn ,.erpbtn{
    padding: 4px 6px;
    gap: 4px;
    border: 0;
    width: 40px;
    height: 36px;
}
.erpbtn {
  color: white;
  background: #5b67eb;
}
.title__container{
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: #000000;
    padding-left: 8px;
  }
}
