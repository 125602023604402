.form__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: fit-content;
  margin-top:90px ;
  padding-bottom: 32px;
  padding-left: 3px;
  margin-left:32px ;
  overflow: hidden;

  /* margin: 120px 0px 40px 320px ; */
}
.title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0px;
  margin: 0px 0px 0px 0px;
  color: rgba(18, 18, 18, 0.87);

}
.sub_title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 8px 0px 8px 0px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(18, 18, 18, 0.6);
}
.url_title {
  width: 93px;
  height: 16px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: rgba(18, 18, 18, 0.87);
}

.url__box {
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px 12px 16px;
  gap: 16px;
  width: 400px;
  height: 40px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.url__box:focus-within{
  border-color: #5b67eb;
  background-color: rgba(0,0,0,0.04);
  box-shadow: 0 0 0 2px rgba(91, 103,235,0.5);
  transition: 0.5s;
}

.url_icon{
  width: 16px;
  height: 16px;
  color: rgba(18, 18, 18, 0.6);
  cursor: pointer;
  flex: none;
 order: 0;
 flex-grow: 0;
}

.url__input {
  padding: 4px;
  gap: 8px;
  background: transparent;
  width: 336px;
  height: 24px;
  outline: none;
  border: 0;
  flex: none;
  order: 1;
  flex-grow: 1;
}
.url__input:focus ~ .url_icon{
  filter: invert(42%) sepia(40%) saturate(5637%) hue-rotate(222deg) brightness(96%) contrast(91%);
}

.url__input::placeholder {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: rgba(18, 18, 18, 0.25);
}

.upload_btn_container{
  margin: 24px 0px 16px 0px;
  
}
.upload_title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: rgba(18, 18, 18, 0.87);
  margin:0px
}
.upload_title span{
  color: rgba(18, 18, 18, 0.6);
}
.upload_sub_title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(18, 18, 18, 0.6);

  margin: 8px 0px 0px 0px;
}

.upload_input_container {
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;
  margin: 16px 0px 0px 0px;
}
.remove_btn{
  cursor: pointer;
  font-size: 14px;
  color: rgba(18, 18, 18, 0.6);
 
}
.remove_btn:hover{
  text-decoration: underline;
 
}

.upload_input_container input::file-selector-button {
  padding: 12px 16px;
  margin:0px 20px 0px 2px;
  color: white;
  background: #5b67eb;
  border-radius: 8px;
  cursor: pointer;
  border:0
}

.upload_input_container input::file-selector-button:hover {
  background-color: #4652ce;
  box-shadow: 0 0 0 2px rgba(91, 103, 235, 0.5);
}
